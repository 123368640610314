import UserData from "../types/UserData";
import Navigation from "./Navigation";
import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert, Box, CssBaseline, CssVarsProvider} from "@mui/joy";
import {MessageContext} from "../hooks/MessageContext";
import {DefaultColorPalette} from "@mui/joy/styles/types/colorSystem";
import {UserDataContext} from "../hooks/UserDataContext";


const Layout: React.FC = () => {
    const [displayMessage, setDisplayMessage] = useState<string | undefined>('');
    const [displayMessageType, setDisplayMessageType] = useState<DefaultColorPalette>("neutral");
    const [userData, setUserData] = useState<UserData | undefined>(() => {
        const savedUserData = localStorage.getItem('userData');
        return savedUserData ? JSON.parse(savedUserData) : undefined;
    });

    const logOut = () => {
        setUserData(undefined);
    }

    const loggedIn = () => {
        return !!userData;
    }

    const sessionIssue = () => {
        logOut();
        setMessage("You've been logged out. Please log in again to continue", "warning")
    }

    useEffect(() => {
        if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
        } else {
            localStorage.removeItem('userData');
        }
    }, [userData]);

    const setMessage = (message: string, messageType: DefaultColorPalette) => {
        setDisplayMessage(message);
        setDisplayMessageType(messageType);
    }

    useEffect(() => {
        if (displayMessage) {
            const timer = setTimeout(() => {
                setDisplayMessage(undefined);
                setDisplayMessageType("neutral");
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [displayMessage]);

    let alert;
    if (displayMessage) {
        alert = <Alert
            variant="soft"
            color={displayMessageType}
            sx={{my: 2, textAlign: "center"}}
        >
            {displayMessage}
        </Alert>
    }

    return <div>
        <CssBaseline/>
        <CssVarsProvider>
            <MessageContext.Provider value={{setMessage: setMessage}}>
                <UserDataContext.Provider value={{userData: userData, logOut: logOut, loggedIn: loggedIn, setUserData: setUserData, sessionIssue: sessionIssue}}>
                    <Navigation/>
                    <Box sx={{
                        mx: 'auto',
                        width: 800,
                    }}>
                        {alert}
                        <Outlet/>
                    </Box>
                </UserDataContext.Provider>
            </MessageContext.Provider>
        </CssVarsProvider>
    </div>
}

export default Layout