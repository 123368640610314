import {Sheet, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import React, {useEffect, useRef, useState} from "react";
import NoteResponse from "../types/NoteResponse";
import ApiResponse from "../types/ApiResponse";
import Note from "./Note";
import {useUserDataContext} from "../hooks/UserDataContext";
import {useMessageContext} from "../hooks/MessageContext";

interface NotesProps {
    bookId: string
    reload: boolean
}

const Notes: React.FC<NotesProps> = ({bookId, reload}) => {
    const [notes, setNotes] = useState<NoteResponse[]>([])
    const {userData, logOut} = useUserDataContext();
    const {setMessage} = useMessageContext();
    const hasFetched = useRef(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(hasFetched.current) {
            return;
        }
        hasFetched.current = true;
        fetchNotes().then((notes) => {
            setNotes(notes);
        })
    }, [reload])

    useEffect(() => {
        hasFetched.current = false;
    }, [reload]);

    const fetchNotes = async (): Promise<NoteResponse[]> => {
        try {
            const response = await fetch(apiUrl + '/books/' + bookId + "/notes", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                },
            });

            let apiResponse = await response.json() as ApiResponse<NoteResponse[]>;
            if (response.ok) {
                return apiResponse.data;
            }
            if (response.status === 403) {
                logOut();
                setMessage("You've been logged out. Please log in again to continue", "warning")
            } else {
                console.log("error response: " + apiResponse.error);
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return [];
    };

    let noNotesMessage;
    if(notes.length === 0) {
        noNotesMessage = <Typography level="body-sm">No messages yet</Typography>
    }


    return <Sheet sx={{
        width: 650,
        my: 4,
        py: 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
    }}>
        <Typography level="body-lg">Notes</Typography>
        {noNotesMessage}
        <Stack gap={2}>
            {notes.map((note: NoteResponse) => (
                <Note note={note} key={note.id}/>
            ))}
        </Stack>
    </Sheet>
}

export default Notes;