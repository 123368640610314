import React, {useState} from "react";
import ApiResponse from "../types/ApiResponse";
import LoginResponse from "../types/LoginResponse";
import {useErrors} from "../hooks/useErrors";
import {Button, Sheet} from "@mui/joy";
import Typography from '@mui/joy/Typography';
import {useNavigate} from "react-router-dom";
import FormFieldWithErrors from "./FormFieldWithErrors";
import FormWithErrors from "./FormWithErrors";
import {useUserDataContext} from "../hooks/UserDataContext";


const SignUp: React.FC = () => {
    const {errors, updateErrors} = useErrors();
    const [error, setError] = useState('')
    const navigate = useNavigate();
    const [formData, setFormData] = useState<Record<string,string>>({ username: '', email: '', password: '' });
    const {setUserData} = useUserDataContext();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        try {
            const response = await fetch(apiUrl + '/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            });

            const parsedResponse = await response.json() as ApiResponse<LoginResponse>;
            console.log(parsedResponse);

            let userData = parsedResponse.data;

            if (response.ok) {
                setUserData({
                    username: userData.username,
                    email: userData.email,
                    token: userData.token,
                })
                navigate("/books");
            } else {
                if (parsedResponse.error) {
                    setError(parsedResponse.error);
                }

                if (parsedResponse.validationErrors) {
                    updateErrors(parsedResponse);
                }
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    return (
        <main>
            <Sheet
                sx={{
                    width: 350,
                    mx: 'auto',
                    my: 4,
                    py: 3,
                    px: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}
                variant="outlined">
                <div>
                    <Typography level="h4" component="h1">
                        <b>Welcome!</b>
                    </Typography>
                    <Typography level="body-sm">Sign up to hear how people liked you book!</Typography>
                </div>
                <FormWithErrors onSubmit={handleSubmit} errors={errors} error={error} setFormData={setFormData} formData={formData}>
                    <FormFieldWithErrors name="email"
                                         type="email"
                                         required={true}
                                         placeholder="your@email"
                                         />

                    <FormFieldWithErrors name="username"
                                         type="text"
                                         required={true}
                                         label="display name"
                                         placeholder="librarian42"
                                         />

                    <FormFieldWithErrors name="password"
                                         type="password"
                                         required={true}
                                         placeholder="password"
                                         />

                    <Button sx={{ mt: 1}} type="submit" value="Sign Up!">Sign Up!</Button>
                </FormWithErrors>
            </Sheet>
        </main>
    )
}

export default SignUp