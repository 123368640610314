import {useEffect, useRef, useState} from "react";
import BookEntity from "../types/BookEntity";
import {Link} from "react-router-dom";
import {format} from 'date-fns';
import ApiResponse from "../types/ApiResponse";
import {Sheet, Table} from "@mui/joy";
import {useUserDataContext} from "../hooks/UserDataContext";
import {formatDistanceToNow} from "date-fns/formatDistanceToNow";

const Books: React.FC = () => {
    const [books, setBooks] = useState<BookEntity[]>([])
    const {userData, sessionIssue} = useUserDataContext();
    const hasFetched = useRef(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(hasFetched.current) {
            return;
        }
        hasFetched.current = true;
        fetchBooks().then((books) => {
            setBooks(books);
        })
    }, [])

    const fetchBooks = async () => {
        try {
            const response = await fetch(apiUrl + '/books', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                },
            });

            const apiResponse = await response.json() as ApiResponse<BookEntity[]>;
            if (response.ok) {
                console.log(apiResponse);
                return apiResponse.data
            }
            if (response.status === 403) {
                sessionIssue();
            } else {
                console.log("error response: " + apiResponse.error);
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return [];
    };

    const formatDate = (dateString: string) => {
        let date = new Date(dateString);
        return format(date, 'yyyy/MM/dd');
    }


    return (
        <Sheet sx={{
                minWidth: 650,
                mx: 'auto',
                my: 4,
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
        >
            <h1>My Donated Books</h1>

            <Table stripe="odd">
                <thead>
                <tr>
                    <td>Donated At</td>
                    <td>Title</td>
                    <td>Author(s)</td>
                    <td>Comments</td>
                    <td>Most Recent Comment At</td>
                </tr>
                </thead>
                <tbody>
                {books.map((book) => (
                    <tr key={book.id}>
                        <td>{formatDate(book.createdAt.toString())}</td>
                        <td><Link to={`/book/${book.id}`}>{book.title}</Link></td>
                        <td>{book.authors}</td>
                        <td>{book.noteCount}</td>
                        <td>{book.mostRecentNotePostedAt ? formatDistanceToNow(book.mostRecentNotePostedAt.toString(), {addSuffix: true}) : "-"}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Sheet>
    )
}

export default Books