import {Card, CardContent, Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import React from "react";
import NoteResponse from "../types/NoteResponse";
import {formatDistanceToNow} from "date-fns/formatDistanceToNow";
import NoteCommentSection from "./NoteCommentSection";
import NoteAction from "./NoteAction";

interface NoteProps {
    note: NoteResponse
}


const Note: React.FC<NoteProps> = ({note}) => {
    const formatDate = (dateString: string) => {
        let date = new Date(dateString);
        return formatDistanceToNow(date, {addSuffix: true})
    }


    return <Card>
        <CardContent>
            <Grid container>
                <Grid xs={6}>
                    <Typography level="title-md" title="username">{note.displayName}</Typography>
                </Grid>
                <Grid xs={6} textAlign={"right"}>
                    <Typography level="title-sm" title="posted at">{formatDate(note.createdAt.toString())}</Typography>
                </Grid>
                <NoteAction note={note}/>
                <NoteCommentSection note={note}/>
            </Grid>
        </CardContent>
    </Card>
}

export default Note;