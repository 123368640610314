import {Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";

const HomePage: React.FC = () => {
    return (
        <Sheet sx={{
            width: 650,
            my: 4,
            py: 3,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: 'sm',
            boxShadow: 'md',
        }}>
            <Typography level="h1">Little Library Card</Typography>
            <Typography>Little Library Card is there to hear how readers liked books you donated to little free libraries or to find out if anyone even picked it up or maybe donated it again. Maybe your book was read by many people and even travelled across the country. Little Library Card is here to help you find out.</Typography>
            <Typography>Just create an account; enter your book providing the ISBN number; print a convenient QR code and place it in the book.</Typography>
            <Typography>You'll get notified whenever someone scans the code and leaves a note for you or updates the book's status.</Typography>
            <Typography>If you encounter any issues or have suggestions for improvements, please create an issue <a href="https://github.com/ajmurmann/LittleLibraryCard/issues">here</a>.</Typography>
        </Sheet>
    )
}

export default HomePage