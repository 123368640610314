import {Box, Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import React from "react";
import NoteResponse from "../types/NoteResponse";

interface Props  {
    note: NoteResponse
}

const NoteCommentSection: React.FC<Props> = ({note}) => {
    if(note.note) {
        return <Grid xs={12} sx={(t) => ({
            border: 1,
            borderWidth: "1px",
            borderColor: t.vars.palette.neutral.outlinedBorder,
            borderRadius: "sm",
            // boxShadow: 'md',
            backgroundColor: t.vars.palette.background.level1,
            p: 1,
            mt: 1,
        })}>
            <Box>
                <Typography fontFamily={"courier"}>{note.note}</Typography>
            </Box>
        </Grid>;
    } else {
        return <Box/>
    }
}

export default NoteCommentSection
