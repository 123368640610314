import React, {useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Sheet} from "@mui/joy";
import {useMessageContext} from "../hooks/MessageContext";
import {useUserDataContext} from "../hooks/UserDataContext";
import ApiResponse from "../types/ApiResponse";

const VerifyEmail: React.FC = () => {
    const navigate = useNavigate();
    const {setMessage} = useMessageContext();
    const {loggedIn} = useUserDataContext();
    const {verificationId} = useParams();
    const hasVerifiedRef = useRef(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        async function verifyEmailAndUpdate() {
            if (hasVerifiedRef.current) {
                return;
            }
            hasVerifiedRef.current = true;
            await verifyEmail();
        }

        verifyEmailAndUpdate();
    }, []);

    const verifyEmail = async (): Promise<void> => {
        try {
            const response = await fetch(apiUrl + '/verifyEmail/' + verificationId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setMessage("Your email is now verified", "success");
            } else if (response.status === 404) {
                setMessage("The link you followed to verify your email is invalid. Please visit your account settings to send a new verification email.", "warning");
            } else {
                let apiResponse = await response.json() as ApiResponse<void>;
                setMessage(apiResponse.error!, "danger");
            }

            if (loggedIn()) {
                navigate("/books");
            } else {
                navigate("/");
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return undefined;
    };

    return (
        <Sheet
            sx={{
                width: 350,
                mx: 'auto',
                my: 4,
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
            variant="outlined">

        </Sheet>
    )
}

export default VerifyEmail