import {FormControl, FormHelperText, FormLabel, Input} from "@mui/joy";
import {SxProps} from "@mui/joy/styles/types";
import React from "react";
import {useFormDataContext} from "../hooks/FormDataContext";

interface FormFieldWithErrorsProps {
    name: string;
    type: string;
    required: boolean;
    label?: string;
    placeholder: string,
    formStylingProps?: SxProps;
}

const FormFieldWithErrors = ({
                                 name,
                                 type,
                                 required,
                                 label,
                                 placeholder,
                                 formStylingProps,
                             }: FormFieldWithErrorsProps) => {
    const { formData, setFormData, errors } = useFormDataContext();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <FormControl error={!!errors[name]} sx={formStylingProps}>
            <FormLabel required={required}>{label ? label : name}</FormLabel>
            <Input
                type={type}
                name={name}
                placeholder={placeholder}
                value={formData[name] || ''} // Controlled component
                onChange={handleChange}
            />
            <FormHelperText>{errors[name]}</FormHelperText>
        </FormControl>
    );
};



export default FormFieldWithErrors