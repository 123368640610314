import {createContext, useContext} from 'react';
import UserData from "../types/UserData";

type UserDataContextType = {
    userData: UserData | undefined;
    loggedIn: () => boolean;
    logOut: () => void;
    setUserData: (userData: UserData) => void;
    sessionIssue: () => void;
};

const UserDataContext = createContext<UserDataContextType | undefined>(undefined);

function useUserDataContext() {
    const context = useContext(UserDataContext);
    if (!context) {
        throw new Error('useUserDataContext must be used within a UserDataContextProvider');
    }
    return context;
}

export { UserDataContext, useUserDataContext };
