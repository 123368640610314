import { createContext, useContext, Dispatch, SetStateAction } from 'react';

type FormDataContextType = {
    formData: Record<string, string>;
    setFormData: Dispatch<SetStateAction<Record<string, string>>>;
    errors: Record<string, string>;
    error: string | undefined;
};

const FormDataContext = createContext<FormDataContextType | undefined>(undefined);

function useFormDataContext() {
    const context = useContext(FormDataContext);
    if (!context) {
        throw new Error('useFormDataContext must be used within a FormDataContextProvider');
    }
    return context;
}

export { FormDataContext, useFormDataContext };
