import {createContext, useContext} from 'react';
import {DefaultColorPalette} from "@mui/joy/styles/types/colorSystem";

type MessageContextType = {
    setMessage: (message: string, messageType: DefaultColorPalette) => void;
};

const MessageContext = createContext<MessageContextType | undefined>(undefined);

function useMessageContext() {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessageContext must be used within a MessageContextProvider');
    }
    return context;
}

export { MessageContext, useMessageContext };
