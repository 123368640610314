import React, {useEffect, useRef, useState} from "react";
import {useUserDataContext} from "../hooks/UserDataContext";
import {Alert, Box, Button, Chip, FormControl, FormLabel, Grid, Link, Sheet, Switch} from "@mui/joy";
import {useMessageContext} from "../hooks/MessageContext";
import ApiResponse from "../types/ApiResponse";
import Typography from "@mui/joy/Typography";

type SettingsFormData = {
    emailMine: boolean;
    emailCommented: boolean;
    email: string;
    emailVerified: boolean;
}

const Account: React.FC = () => {
    const {userData, sessionIssue} = useUserDataContext();
    const [formData, setFormData] = useState<SettingsFormData>({
        emailMine: true,
        emailCommented: true,
        email: userData!.email,
        emailVerified: false
    })
    const [error, setError] = useState<string | undefined>(undefined);
    const {setMessage} = useMessageContext();
    const hasFetched = useRef(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        async function fetchAndSetSettings() {
            if(hasFetched.current) {
                return;
            }
            hasFetched.current = true;
            let settingsResponse = await fetchSettings()
            if (settingsResponse) {
                setFormData(settingsResponse);
            }
        }

        fetchAndSetSettings();
    }, []);

    const requestVerificationEmail = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            const response = await fetch(apiUrl + '/resendVerification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                }
            });

            if (response.status === 201) {
                setMessage("A new verification email was sent to your email address", "success");
            } else if (response.status === 403) {
                sessionIssue();
            } else {
                response.json().then((jsonResponse) => {
                    let apiResponse = jsonResponse as ApiResponse<void>;
                    setError(apiResponse.error);
                })
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const fetchSettings = async (): Promise<SettingsFormData | undefined> => {
        try {
            const response = await fetch(apiUrl + '/settings', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                }
            });

            let apiResponse = await response.json() as ApiResponse<SettingsFormData>;
            if (response.status === 200) {
                return apiResponse.data
            } else if (response.status === 403) {
                sessionIssue();
            } else {
                setError(apiResponse.error);
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return undefined
    };

    const updateSettings = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            const response = await fetch(apiUrl + '/settings', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData?.token
                },
                body: JSON.stringify(formData),
            });

            let apiResponse = await response.json() as ApiResponse<SettingsFormData>;
            if (response.status === 200) {
                setFormData(apiResponse.data);
            } else if (response.status === 403) {
                sessionIssue();
            } else {
                setError(apiResponse.error);
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const errorMessage = error ? <Alert variant="soft" color="danger" sx={{mb: 1}}>{error}</Alert> : <Box></Box>;

    const verificationLink = formData.emailVerified ? "" :
        <Link onClick={requestVerificationEmail} underline="none" sx={{ml: 3}}>Resend Verification Link!</Link>

    // <Typography level="body-md"><form onSubmit={requestVerificationEmail}>
    //     <Button variant="plain">Resend Verification Link!</Button>
    // </form></Typography>

    return <Sheet sx={{
        width: 650,
        my: 4,
        py: 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
    }}
    ><Box>
        {errorMessage}
        <form onSubmit={updateSettings}>
            <Typography level="h4" component="h1">
                <b>Your Account</b>
            </Typography>
            <Grid container spacing={2}>
                <Grid xs={6}>
                    <Typography level="body-md">Email</Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography level="body-md">{userData?.email}</Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography level="body-md">Email Verified?</Typography>
                </Grid>
                <Grid xs={6}>
                    <Chip color={formData.emailVerified ? "success" : "danger"}>{formData.emailVerified ? "✔️" : "X"}
                    </Chip>
                    {verificationLink}
                </Grid>
                <Grid xs={12}>
                    <FormControl
                        sx={{display: "flex", flexWrap: "nowrap", flexDirection: "row", alignItems: "flex-start"}}>
                        <FormLabel sx={{width: 0.5}}><Typography level="body-md">Notify on updates on my
                            books</Typography></FormLabel>
                        <Switch
                            sx={{width: 0.4, pl: 1}}
                            checked={formData.emailMine}
                            onChange={(e) => setFormData({...formData, emailMine: e.target.checked})}
                        />
                    </FormControl>
                </Grid>
                <Grid xs={12}>
                    <FormControl
                        sx={{display: "flex", flexWrap: "nowrap", flexDirection: "row", alignItems: "flex-start"}}>
                        <FormLabel sx={{width: 0.5}}><Typography level="body-md">Notify on updates on books I interacted
                            with</Typography></FormLabel>
                        <Switch
                            sx={{width: 0.4, pl: 1}}
                            checked={formData.emailCommented}
                            onChange={(e) => setFormData({...formData, emailCommented: e.target.checked})}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{textAlign: "right"}}>
                <Button sx={{mt: 1}} type="submit" value="Post">Update</Button>
            </Box>
        </form>
    </Box>
    </Sheet>
}

export default Account;