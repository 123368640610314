import LoginForm from "./LoginForm";
import {Link as RouterLink,} from 'react-router-dom';
import {Box, Link, Sheet} from "@mui/joy";
import React from "react";
import {useUserDataContext} from "../hooks/UserDataContext";


const Navigation: React.FC = () => {
    let navOptions

    const {loggedIn} = useUserDataContext();

    if (loggedIn()) {
        navOptions = <Box sx={{display: "flex"}}>
            <Link variant="outlined" color="neutral"
                  underline="none" component={RouterLink} to='/books'
                  sx={{'--Link-gap': '0.5rem', pl: 1, py: 0.5, borderRadius: 'md', mx: 1}}>
                My Books</Link>
            <Link variant="outlined" color="neutral"
                  underline="none" component={RouterLink} to='/book/new'
                  sx={{'--Link-gap': '0.5rem', pl: 1, py: 0.5, borderRadius: 'md', mx: 1}}>
                New Book</Link>
        </Box>
    }

    return (
        <Sheet sx={(t) => ({
            borderBottom: 1,
            borderBottomColor: t.vars.palette.divider,
            boxShadow: "sm",
            mx: 0,
            p: 1,
            displayPrint: 'none',
        })}>
            <Box sx={{
                justifyContent: "space-between",
                flexWrap: "nowrap",
                display: "flex",
            }}>
                <Box sx={{display: "flex"}}>
                    <Box sx={{alignItems: "center", mr: 2}}>
                        <Link component={RouterLink} to="/" underline="none" color="neutral" level="title-lg" sx={{lineHeight: 2}}>
                            Little Library Card
                        </Link>
                    </Box>
                    <Box>
                        {navOptions}
                    </Box>
                </Box>
                {LoginForm()}
            </Box>
        </Sheet>
    )
}

export default Navigation