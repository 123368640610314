import {useState} from "react";
import {useMessageContext} from "../hooks/MessageContext";
import FormWithErrors from "./FormWithErrors";
import ApiResponse from "../types/ApiResponse";
import Typography from "@mui/joy/Typography";
import {Button, Sheet} from "@mui/joy";
import FormFieldWithErrors from "./FormFieldWithErrors";
import {useErrors} from "../hooks/useErrors";
import {useNavigate, useParams} from "react-router-dom";

const PasswordReset = () => {
    const {setMessage} = useMessageContext();
    const initialFormState = {newPassword: ''};
    const [formData, setFormData] = useState<Record<string, string>>(initialFormState)
    const [error, setError] = useState('');
    const {errors, updateErrors} = useErrors();
    const {resetId} = useParams();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await fetch(apiUrl + '/settings/reset', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...formData, resetId: resetId}),
            });

            const parsedResponse = await response.json() as ApiResponse<void>;
            if (response.ok) {
                setMessage(
                    "Your password was successfully set",
                    "success")
                setFormData(initialFormState);
                navigate("/");
            } else {
                if (parsedResponse.error) {
                    setError(parsedResponse.error);
                }

                if (parsedResponse.validationErrors) {
                    updateErrors(parsedResponse);
                }
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    return <Sheet
        sx={{
            width: 350,
            mx: 'auto',
            my: 4,
            py: 3,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: 'sm',
            boxShadow: 'md',
        }}
        variant="outlined">
        <div>
            <Typography level="h4" component="h1">
                <b>Reset Password</b>
            </Typography>
        </div>
        <FormWithErrors onSubmit={handleSubmit} formData={formData} error={error} errors={errors} setFormData={setFormData}>
            <FormFieldWithErrors name="newPassword"
                                 type="password"
                                 label="new Password"
                                 required={true}
                                 placeholder=""
            />
            <Button sx={{ mt: 1}} type="submit" value="Reset">Reset!</Button>
        </FormWithErrors>
    </Sheet>
}

export default PasswordReset