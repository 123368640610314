import {Alert, Box} from "@mui/joy";
import StyledForm from "./StyledForm";
import {SxProps} from "@mui/joy/styles/types";
import {Dispatch, ReactNode, SetStateAction} from "react";
import {FormDataContext} from "../hooks/FormDataContext";

interface FormWithErrorsProps {
    children: ReactNode;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    formData: Record<string, any>;
    error: string | undefined;
    errors: Record<string, string>;
    setFormData: Dispatch<SetStateAction<Record<string,any>>>;
    formStylingProps?: SxProps;
}

const FormWithErrors = ({
                            children,
                            onSubmit,
                            formData,
                            error,
                            errors,
                            setFormData,
                            formStylingProps,
                        }: FormWithErrorsProps) => {
    const errorMessage = error ? <Alert variant="soft" color="danger" sx={{mb: 1}}>{error}</Alert> : <Box></Box>;

    return (
        <Box>
            {errorMessage}
            <StyledForm onSubmit={onSubmit} sx={{ ...formStylingProps }}>
                <FormDataContext.Provider value={{ formData, setFormData, error, errors }}>
                    {children}
                </FormDataContext.Provider>
            </StyledForm>
        </Box>
    );
};

export default FormWithErrors;