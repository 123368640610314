import {Box, Grid} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import React from "react";
import NoteResponse from "../types/NoteResponse";

interface Props  {
    note: NoteResponse
}

const NoteAction: React.FC<Props> = ({note}) => {
    let sentence;
    let address = note.addressZip ? note.addressZip : "N/A";

    if(note.pickedUp) {
        sentence = "picked the book up at " + address
    }
    if(note.droppedOff) {
        sentence = "dropped the book off at " + address
    }
    if(sentence){
        return <Grid xs={12} display="flex">
            <Typography level="title-sm">{sentence}</Typography>
        </Grid>
    } else {
        return <Box/>
    }
}

export default NoteAction
