import {QRCodeSVG} from 'qrcode.react';
import React, {useState} from "react";
import {Box, Input, Link, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";

const Book: React.FC = () => {
    const [greeting, setGreeting] = useState("Scan this code to let me know how you liked the book")

    return (
        <Sheet sx={{
            width: 650,
            my: 4,
            py: 3,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <Input type="text" sx={{displayPrint: 'none', mb: 2}} value={greeting} onChange={(e) => {setGreeting(e.target.value)}}></Input>
            <Box sx={{border: 1, padding: 2, textAlign: "center", displayPrint: "block"}}>
                <QRCodeSVG value={window.location.toString()}/>
                <Typography level="body-md">{greeting}</Typography>
            </Box>
            <Typography level="body-sm" sx={{displayPrint: 'none',}}>Only the box above will be visible when printing</Typography>
            <Link href={window.location.toString().replace("/print", "")}>Back to book</Link>
        </Sheet>
    )
}

export default Book