// useErrors.ts
import { useState } from 'react';
import ApiResponse from "../types/ApiResponse";

export const useErrors = () => {
    const [errors, setErrors] = useState<Record<string, string>>({});

    const updateErrors = <T>(apiResponse: ApiResponse<T>) => {
        resetErrors();
        if (apiResponse.validationErrors) {
            Object.entries(apiResponse.validationErrors).forEach(([key, value]) => {
                putError(key, value);
            });
        }
    };

    const resetErrors = () => {
        setErrors({});
    };

    const putError = (key: string, value: string) => {
        setErrors((prev) => ({ ...prev, [key]: value }));
    };

    return { errors, updateErrors, resetErrors, putError };
};
